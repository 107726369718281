var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Member',{attrs:{"title":"Prof. Giampiero Negri","description":"Primario Unità Operativa di Chirurgia Toracica","text":"O4/04/2017 – Direttore Scuola Specializzazione in Chirurgia Toracica, Università Vita-Salute San Raffaele, Milano;\n\n01/01/2011 – Professore Associato di Chirurgia Toracica, Università Vita-Salute San Raffaele, Milano;\n\n01/11/1999 – 31/10/2010: Ricercatore Universitario di Chirurgia Toracica, Università Vita – Salute San Raffaele, Milano;\n\n21/04/1998 – 31/10/1999: Ricercatore Universitario di Chirurgia Toracica, Università degli Studi di Milano;\n\n16/05/1991 – 20/04/1998: Ricercatore Universitario di Chirurgia Generale, Università degli Studi di Milano;\n\n1982 – 1984: Borsista, Università degli Studi di Milano.\n\n01/03/2017 – Primario Unità Operativa di Chirurgia Toracica, Ospedale San Raffaele, Milano\n\n01/01/2002 – 28/02/2017: Responsabile Unità Funzionale di Chirurgia Toracica Mini-Invasiva, Ospedale San Raffaele, Milano;\n\n01/06/1996 – 31/12/2001: Funzioni di Aiuto nell’ambito del ruolo di Ricercatore Universitario, Unità Operativa di Chirurgia Toracica, Ospedale San Raffaele, Milano;\n\n01/01/1993 – 31/05/1996: Funzioni di Assistente nell’ambito del ruolo di Ricercatore Universitario, Unità Operativa di Chirurgia Toracica, Ospedale San Raffaele, Milano;\n\n31/05/1991 – 31/12/1992: Funzioni di Assistente nell’ambito del ruolo di Ricercatore Universitario, Istituto di Chirurgia Generale, Ospedale Maggiore-Policlinico, Milano;\n\n05/01/1989 – 30/05/1991: Assistente Ospedaliero, Istituto di Clinica Chirurgica I, Ospedale Maggiore-Policlinico, Milano;\n\n1985 – 1988: Borsista, Istituto di Clinica Chirurgica I, Ospedale Maggiore-Policlinico, Milano.","formation":[
      '1979: Laurea in Medicina e Chirurgia, Università di Perugia',
      '1984: Specializzazione in Chirurgia Generale, Università di Milano',
      '1987: Specializzazione in Chirurgia Toracica, Università di Torino',
      '1992: Visiting Fellow, Mass General Hospital di Boston',
      '1987: Visiting Fellow, St. Mark’s Hospital di Londra.',
    ],"publication":"Autore di oltre 200 pubblicazioni scientifiche su riviste nazionali ed internazionali, 12 capitoli di libri, 11 video-cinè clinic.","pub":true,"headerImage":_vm.localImage}})
}
var staticRenderFns = []

export { render, staticRenderFns }
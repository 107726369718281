<template>
  <Member
    title="Prof. Giampiero Negri"
    description="Primario Unità Operativa di Chirurgia Toracica"
    text="O4/04/2017 – Direttore Scuola Specializzazione in Chirurgia Toracica, Università Vita-Salute San Raffaele, Milano;

01/01/2011 – Professore Associato di Chirurgia Toracica, Università Vita-Salute San Raffaele, Milano;

01/11/1999 – 31/10/2010: Ricercatore Universitario di Chirurgia Toracica, Università Vita – Salute San Raffaele, Milano;

21/04/1998 – 31/10/1999: Ricercatore Universitario di Chirurgia Toracica, Università degli Studi di Milano;

16/05/1991 – 20/04/1998: Ricercatore Universitario di Chirurgia Generale, Università degli Studi di Milano;

1982 – 1984: Borsista, Università degli Studi di Milano.

01/03/2017 – Primario Unità Operativa di Chirurgia Toracica, Ospedale San Raffaele, Milano

01/01/2002 – 28/02/2017: Responsabile Unità Funzionale di Chirurgia Toracica Mini-Invasiva, Ospedale San Raffaele, Milano;

01/06/1996 – 31/12/2001: Funzioni di Aiuto nell’ambito del ruolo di Ricercatore Universitario, Unità Operativa di Chirurgia Toracica, Ospedale San Raffaele, Milano;

01/01/1993 – 31/05/1996: Funzioni di Assistente nell’ambito del ruolo di Ricercatore Universitario, Unità Operativa di Chirurgia Toracica, Ospedale San Raffaele, Milano;

31/05/1991 – 31/12/1992: Funzioni di Assistente nell’ambito del ruolo di Ricercatore Universitario, Istituto di Chirurgia Generale, Ospedale Maggiore-Policlinico, Milano;

05/01/1989 – 30/05/1991: Assistente Ospedaliero, Istituto di Clinica Chirurgica I, Ospedale Maggiore-Policlinico, Milano;

1985 – 1988: Borsista, Istituto di Clinica Chirurgica I, Ospedale Maggiore-Policlinico, Milano."
    :formation="[
      '1979: Laurea in Medicina e Chirurgia, Università di Perugia',
      '1984: Specializzazione in Chirurgia Generale, Università di Milano',
      '1987: Specializzazione in Chirurgia Toracica, Università di Torino',
      '1992: Visiting Fellow, Mass General Hospital di Boston',
      '1987: Visiting Fellow, St. Mark’s Hospital di Londra.',
    ]"
    publication="Autore di oltre 200 pubblicazioni scientifiche su riviste nazionali ed internazionali, 12 capitoli di libri, 11 video-cinè clinic."
    :pub="true"
    :headerImage="localImage"
  />
</template>

<script>
import Member from "../routes/Member";
import teamImage from "@/assets/member-background.jpg";
export default {
  name: "Negri",
  components: {
    Member,
  },
  data() {
    return {
      localImage: teamImage,
    };
  },
};
</script>
